import React, { useEffect, useRef } from 'react';
import './Services.css';
import logofigma from "../assets/logo_figma.svg";
import logodesarrollo from "../assets/logo_desarrollo.svg";
import logohosting from "../assets/logo_hosting.svg";
import { Row, Col, Container, Image } from 'react-bootstrap';

export function Services() {
    const servicesRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const servicesElements = document.querySelectorAll('.service-item, .service-title-item p, .service-title-item h2');

            servicesElements.forEach((element) => {
                const bounding = element.getBoundingClientRect();

                if (bounding.top < window.innerHeight - 100 && !element.classList.contains('animate')) {
                    element.classList.add('animate');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Container className='back-services align-items-center' id='services' ref={servicesRef}>
                <Row className='justify-content-center service-container'>
                    <Col xs={10} xl={12} className='service-title-item'>
                        <p className='service-title-section'>SOMOS ESPECIALISTAS EN BRINDAR</p>
                        <h2>EL SERVICIO QUE NECESITAS</h2>
                        <Col xl={8}><p className='service-subtitle'>Desarrollamos páginas web de alto impacto apuntadas a maximizar resultados. Somos especialistas en generar soluciones a medida y diseño personalizado para cada cliente.</p></Col>
                    </Col>
                </Row>

                <Row className='justify-content-center mt-3'>
                    <Col xs={10} className='service-left-style d-flex mt-4 align-items-center service-item'>
                        <Image className='service-icon-left mobile-figma' alt='logo-figma' src={logofigma}></Image>
                        <Col>
                            <h2 className='service-title'>DISEÑO WEB</h2>
                            <p className='service-text'>Cada detalle, desde la navegación hasta la estética, se diseña estratégicamente para cautivar a tu audiencia. Nuestro enfoque centrado en el usuario garantiza una experiencia intuitiva y atractiva, mejorando la retención y la satisfacción del usuario.</p>
                        </Col>
                    </Col>
                </Row>

                <Row className='justify-content-center mt-3'>
                    <Col xs={10} className='service-right-style d-flex mt-4 align-items-center service-item'>
                        <Col>
                            <h2 className='service-title'>DESARROLLO WEB</h2>
                            <p className='service-text-dev'>Eleva tu presencia digital. Creamos sitios web altamente funcionales para destacar tu marca en el competitivo mundo en línea. Desde la conceptualización hasta el lanzamiento, cada fase está impulsada por tecnologías de vanguardia para una experiencia única del usuario.</p>
                        </Col>
                        <Image className='service-icon-rigth mobile-desarrollo' src={logodesarrollo} alt='ilustracion representativa desarrollo web'></Image>
                    </Col>
                </Row>

                <Row className='justify-content-center mt-3'>
                    <Col xs={10} className='service-left-style d-flex mt-4 align-items-center mb-services service-item'>
                        <Image className='service-icon-left d-xs-none mobile-diseno' src={logohosting} alt='ilustracion representativa hosting'></Image>
                        <Col>
                            <h2 className='service-title'>HOSTING</h2>
                            <p className='service-text'>Ofrecemos soluciones seguras y confiables con una infraestructura escalable. Velocidad, seguridad y soporte técnico excepcional son la esencia de nuestro servicio, brindándote la tranquilidad para concentrarte en hacer crecer tu negocio mientras cuidamos de tu infraestructura digital.</p>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
