import React from 'react';
import './Whatsapp.css';
import whatsapp from '../assets/whatsapp.png';

export const Whatsapp = () => {
    return (
        <a href='https://api.whatsapp.com/send?phone=541154687337&text=Hola%20Higher%20Web!%20Quiero%20solicitar%20presupuesto%20de...%20' className="float" target="_blank" rel="noopener noreferrer">
            <img src={whatsapp} alt="logo-whatsapp"/>
        </a>
    );
}

