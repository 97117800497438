import Button from 'react-bootstrap/Button';
import './Button.css'
// import { Col } from 'react-bootstrap';

export function ButtonPrincipal(props) {
  return (
    <>
      <Button type='submit' className={`button-style mt-3 ${props.className}`}>
      {props.texto}</Button>
    </>
  );
}
