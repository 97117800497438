import React, { useEffect, useState, useRef } from 'react';
import './Contact.css';
import contacthigherimg from "../assets/higherweb-contact.png";
import { Row, Col, Container, Form, Image } from 'react-bootstrap';
import { ButtonPrincipal } from './Button';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

export function Contact() {
    const contactRef = useRef(null);

    const captcha = useRef(null);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: ''
    });
    const [formErrors, setFormErrors] = useState({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: ''
    });

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...formErrors };

        // Validación del nombre
        if (formData.nombre.trim() === '') {
            newErrors.nombre = 'Por favor ingresa tu nombre';
            valid = false;
        } else {
            newErrors.nombre = '';
        }

        // Validación del email
        if (formData.email.trim() === '') {
            newErrors.email = 'Por favor ingresa tu email';
            valid = false;
        } else {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(formData.email)) {
                newErrors.email = 'Por favor ingresa un email válido';
                valid = false;
            } else {
                newErrors.email = '';
            }
        }

        // Validación del teléfono
        if (formData.telefono.trim() === '') {
            newErrors.telefono = 'Por favor ingresa tu teléfono';
            valid = false;
        } else {
            newErrors.telefono = '';
        }

        // Validación del mensaje
        if (formData.mensaje.trim() === '') {
            newErrors.mensaje = 'Por favor ingresa tu mensaje';
            valid = false;
        } else {
            newErrors.mensaje = '';
        }

        setFormErrors(newErrors);
        return valid;
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const submit = (e) => {
        e.preventDefault();

        if (validateForm() && captchaVerified) {
            console.log('Enviando formulario:', e.target.action);
            e.target.submit();

            Swal.fire({
                text: "¡Gracias por enviar tu formulario! Nos pondremos en contacto contigo pronto.",
                icon: 'success',
            });
        } else {
            let errorMessage = "Por favor completa correctamente todos los campos.";
            if (!captchaVerified) {
                errorMessage = "Por favor, acepta el captcha para enviar el formulario";
            }
            Swal.fire({
                text: errorMessage,
                confirmButtonColor: '#A259FF',
                borderColor: '#A259FF'
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const contactElements = document.querySelectorAll('.contact-animate');

            contactElements.forEach((element) => {
                const bounding = element.getBoundingClientRect();

                if (bounding.top < window.innerHeight - 100 && !element.classList.contains('animate')) {
                    element.classList.add('animate');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Container className='contact align-items-center justify-content-center pt-xs-2 pt-lg-4' id='contact' ref={contactRef}>
                <Row className='d-flex justify-content-center'>
                    <Col xs={10} xl={6} className='contact-animate'>
                        <p className='mt-4'>COMUNICATE CON NOSOTROS</p>
                        <h2>SOLICITÁ TU PRESUPUESTO</h2>
                        <Col xl={8}>
                            <p className='service-subtitle'>¿Estás listo para llevar tu presencia digital al siguiente nivel?</p>
                        </Col>
                        <Col>
                            <Image src={contacthigherimg} alt='ilustracion de astronauta escribiendo en laptop'></Image>
                        </Col>
                    </Col>

                    <Col xs={10} xl={6} className='mt-5 contact-animate'>
                        <Form method="POST" action="contacto.php" onSubmit={submit}>
                            <Col className='mt-3'>
                                <Form.Control className='form-style' type="text" placeholder="Nombre" name='nombre' value={formData.nombre} onChange={onChange} />
                                {formErrors.nombre && <p className="error-message">{formErrors.nombre}</p>}
                            </Col>
                            <Col className='mt-3'>
                                <Form.Control className='form-style' placeholder="Email" type="email" name='email' value={formData.email} onChange={onChange} />
                                {formErrors.email && <p className="error-message">{formErrors.email}</p>}
                            </Col>
                            <Col className='mt-3'>
                                <Form.Control className='form-style' placeholder="Teléfono" type="text" name='telefono' value={formData.telefono} onChange={onChange} />
                                {formErrors.telefono && <p className="error-message">{formErrors.telefono}</p>}
                            </Col>
                            <Form.Control as="textarea" placeholder="Mensaje" className='text-area form-style mt-3' name='mensaje' value={formData.mensaje} onChange={onChange} rows={6} />
                            {formErrors.mensaje && <p className="error-message">{formErrors.mensaje}</p>}

                            <ReCAPTCHA 
                                className='mt-3'
                                ref={captcha}
                                sitekey="6Lc81JcpAAAAAIKPV4zjadXHbzBPPw64r8axfvkE" 
                                onChange={() => setCaptchaVerified(true)} 
                            />

                            <ButtonPrincipal className='mt-5 col-12 mb-5' texto='ENVIAR' disabled={!captchaVerified} />
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
