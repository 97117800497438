import React from 'react';
import Button from 'react-bootstrap/Button';

export const ButtonHero = ({ texto, onClick, className }) => {
    return (
        <Button className={className} onClick={onClick}>
            {texto}
        </Button>
    );
}
