import { NavBar } from './components/NavBar';
import { Hero } from './components/Hero';
import { Services } from './components/Services';
import { Contact } from './components/Contact';
import { Whatsapp } from './components/Whatsapp';
// import { Instagram } from './components/Instagram';
import './App.css';


function App() {
  return (
    <>
      {/* <Instagram /> */}
      <Whatsapp />
      <NavBar />
      <Hero />
      <Services />
      <Contact />
    </>
  );
}

export default App;