import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../assets/logo-blanco.png";
import './NavBar.css';

export function NavBar() {
  const [currentSection, setCurrentSection] = useState('home');

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      const yOffset = -100;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const servicesSection = document.getElementById('services');
    const contactSection = document.getElementById('contact');

    const windowHeight = window.innerHeight;

    if (
      scrollPosition < servicesSection.offsetTop - windowHeight / 2
    ) {
      setCurrentSection('home');
    } else if (
      scrollPosition < contactSection.offsetTop - windowHeight / 2
    ) {
      setCurrentSection('services');
    } else {
      setCurrentSection('contact');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar expand="lg" className="fixed-top nav-style">
        <Container className='navbar-container'>
          <Navbar.Brand onClick={() => scrollToSection('home')}>
            <img src={logo} alt='logo' className='logo-respons mt-xl-3 mt-md-4 mt-xs-1' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
            <Nav className="ml-auto">
              <Nav.Link
                onClick={() => scrollToSection('hero')}
                className={`mx-xl-3 mx-lg-3 navbar-font font-nav-mob ${currentSection === 'home' ? 'active' : ''}`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                onClick={() => scrollToSection('services')}
                className={`mx-xl-3 mx-lg-3 navbar-font font-nav-mob ${currentSection === 'services' ? 'active' : ''}`}
              >
                Servicios
              </Nav.Link>
              <Nav.Link
                onClick={() => scrollToSection('contact')}
                className={`mx-xl-3 mx-lg-3 font-nav-mob ${currentSection === 'contact' ? 'active' : ''}`}
              >
                Contacto
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
