import React, { useEffect, useRef } from 'react';
import './Hero.css';
import portadaimg from "../assets/portada1.png";
import { ButtonHero } from './ButtonHero';
import { Row, Col, Container } from 'react-bootstrap';

export function Hero() {
    const heroRef = useRef(null);

    useEffect(() => {
        const row = heroRef.current;
        row.classList.add('animated');

        return () => {
            row.classList.remove('animated');
        };
    }, []);

    function scrollToElement(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <Container id='hero' className='portada-back d-flex'>
            <Row className='row portada-direction align-items-center' ref={heroRef}>
                <Col xs={10} sm={7} md={7} lg={8} xl={8} className='flex-column justify-content-center portada-cent'>
                    {/* <p className='texto-portada p-2'>Te acompañamos en el crecimiento y desarrollo de tu empresa de una manera óptima, mediante <strong>soluciones tecnológicas rápidas y efectivas.</strong></p> */}
                    <p className='texto-portada-2 p-2'>LE DAMOS A TU EMPRESA LA PRESENCIA DIGITAL QUE NECESITA</p>
                    <ButtonHero className='col-12 col-xl-6 button-style' texto='CONTACTANOS' onClick={() => scrollToElement('contact')} />
                </Col>

                <Col xs={5} md={5} lg={4} xl={4} className='text-center'>
                    <img src={portadaimg} className='img-portada width-img' alt='ilustracion de cohete despegando de una notebook' loading='lazy' />
                </Col>
            </Row>
            
            <link rel="preload" href={portadaimg} as="image" />
            <link rel="preconnect" href="https://higherweb.com.ar" />

        </Container>
    );
}
